import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import { FlatList } from "react-native";

// Customizable Area End

import ReservationHistoryController, {
  Props,
  configJSON,
  Item,
} from "./ReservationHistoryController";
import moment from "moment";

export default class ReservationHistory extends ReservationHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.requestSessionData();
  }

  renderItem = (item: Item) => {
    return (
      <Box
        sx={webStyle.productContainer}
      >
        <Box
          sx={webStyle.productInfoContainer}
        >
          <img data-test-id="productImgTestID" src={item.attributes.reservation_service_image} style={webStyle.productImage} />
          <Box
            sx={webStyle.productInfoSubContainer}
          >
            <Typography data-test-id="productNameTestID" variant="body2">{item.attributes.reservation_service.service_name}</Typography>
              <Typography data-test-id="productQuantityTestID" variant="body2">
                Date: {moment(item.attributes.reservation_service.reservation_date).format("DD-MMM-YYYY")}
            {" "}{item.attributes.reservation_service.slot_start_time}</Typography>
              <Typography data-test-id="productIDTestID" variant="body2">
              Reservation status: {item.attributes.order_status}
              </Typography>
            <Typography data-test-id="productPriceTestID" variant="body2">Place: {item.attributes.reservation_service.city}</Typography>
          </Box>
        </Box>
          <Box
            data-test-id="btnAddExample"
            component="button"
            sx={webStyle.webButtonStyle}
          >
            <Button
              data-test-id="produtCancelBtnTestID"
              color={"primary"}
              onClick={() => {
                this.handleViewBooking(item)
              }}
            >{"View Details"}</Button>
          </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainContainer}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Typography variant="subtitle1" component="div">
              {configJSON.labelBodyText}
            </Typography>

            <Typography variant="subtitle1">
            {configJSON.sortBy}
            </Typography>
            
            <Box sx={webStyle.modal_footer}>
              {
                this.sortTypes.map((item:string, index:number) => {
                  return (
                    <Box
                      key={item}
                      data-test-id="btnAddExample"
                      component="button"
                      sx={this.state.sortType === item ? webStyle.mdlSortSelectedWrapper : webStyle.mdlSortWrapper}
                    >
                      <Button
                        data-test-id={`sortUpdateBtn-${index}`}
                        color={"primary"}
                        onClick={() => { this.doSortingPressed(item) }}
                      >{item}</Button>
                    </Box>
                  )
                })
              }
            </Box>

            {this.state.itemsList && this.state.itemsList.length > 0 && (
              <FlatList
                data-test-id="productListTestID"
                data={this.state.itemsList}
                renderItem={({ item }) => {
                  return (this.renderItem(item))
                }}
                keyExtractor={item => item.id}
              />
            )}
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  webButtonStyle: {
    backgroundColor: "#2196F3",
    alignSelf: "flex-start",
    marginVertical: 10,
    padding: 10
  },
  productImage: {
    width: "100px",
    height: "100%",
    marginRight: "10px",
  },
  productContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "100px",
    marginBottom: "20px",
    alignItems: "center",
    flex: 1
  },
  filterContainer: {
    marginBottom: '10px',
    display: "flex",
    flexDirection: "row",
    gap: '10px',
    backgroundColor: "pink",
    width: '100%'
  },
  productInfoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  productInfoSubContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "5px"
  },
  modal: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modal_content: {
    width: "500px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modal_header: {
    padding: "10px",
  },
  modal_body: {
    padding: "10px",
    borderTop: "1px solid #eee",
    borderBottom: "1px solid #eee"
  },
  modal_footer: {
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    borderTop: "1px solid #eee",
  },
  inputContainer: {
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mdlSortSelectedWrapper: {
    alignSelf: 'flex-start',
    backgroundColor: "green",
  },
  mdlSortWrapper: {
  },
};
// Customizable Area End
