import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Modal,
  ButtonGroup,
  Divider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import moment from "moment";

// Customizable Area End

import ReservationsItemController, {
  Props,
  configJSON,
  HistoryItem,
} from "./ReservationsItemController";
import { FlatList } from "react-native";

export default class ReservationsItem extends ReservationsItemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.requestSessionData();
  }

  renderItem = (item: HistoryItem) => {
    return (
      <Box sx={webStyle.productWrapper}>
        <Box sx={webStyle.productInfoContainer}>
          {item.attributes.reservation_service_image !== null &&
            item.attributes.reservation_service_image !== undefined && (
              <img
                data-test-id="productImgTestID"
                src={item.attributes.reservation_service_image}
                style={webStyle.productImageWrapper}
              />
            )}
          {item.attributes.image ? (
            <img
              data-test-id="productImgTestID"
              src={item.attributes.image}
              style={webStyle.productImageWrapper}
            />
          ) : (
            <Box style={webStyle.productImageWrapper} />
          )}
          <Box>
            <Typography data-test-id="productNameTestID" variant="subtitle2">
              {item.attributes.service_name ??
                item.attributes.reservation_service.service_name}
            </Typography>
            <Typography data-test-id="productDateTestID" variant="subtitle2">
              Date:{" "}
              {moment(
                item.attributes.reservation_date ??
                  item.attributes.reservation_service.reservation_date
              ).format("DD-MMM-YYYY")}
            </Typography>
            <Typography data-test-id="productStatusTestID" variant="subtitle2">
              Time:{" "}
              {item.attributes.slot_start_time ??
                item.attributes.reservation_service.slot_start_time}{" "}
              -{" "}
              {item.attributes.slot_end_time ??
                item.attributes.reservation_service.slot_end_time}
            </Typography>
            <Typography data-test-id="productPlaceTestID" variant="subtitle2">
              booking status:{" "}
              {item.attributes.booking_status ??
                item.attributes.reservation_service.booking_status}
            </Typography>
            <Typography data-test-id="productPlaceTestID" variant="subtitle2">
              Price:{" "}
              {item.attributes.price ??
                item.attributes.reservation_service.price}
            </Typography>
            <Typography data-test-id="productPlaceTestID" variant="subtitle2">
              Place:{" "}
              {item.attributes.city ?? item.attributes.reservation_service.city}
            </Typography>
            <Typography data-test-id="productPlaceTestID" variant="subtitle2">
              Address:{" "}
              {item.attributes.full_address ??
                item.attributes.reservation_service.full_address}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainContainer}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Typography variant="subtitle1" component="div">
              {"Reservation Service Item Details"}
            </Typography>

            {this.itemData &&
              //  istanbul ignore next
              this.renderItem(this.itemData)}
            {/* istanbul ignore next */}
            {this.type === "booked" &&
            this.itemData.attributes &&
            this.itemData.attributes.order_status &&
            this.itemData.attributes.order_status === "placed" ? (
              //  istanbul ignore next
              <Box sx={webStyle.rowButtons}>
                <Box style={webStyle.flex1Margin10L}>
                  <Button
                    data-test-id="produtEditBtnTestID"
                    color={"primary"}
                    onClick={
                      //  istanbul ignore next
                      () => this.doButtonEditPressed()
                    }
                  >
                    {configJSON.btnEditTitle}
                  </Button>
                </Box>
                <Box style={webStyle.flex1Margin10L}>
                  <Button
                    data-test-id="produtCancelBtnTestID"
                    color={"primary"}
                    onClick={
                      //  istanbul ignore next
                      () => this.doButtonCancelPressed()
                    }
                  >
                    {configJSON.btnCancelTitle}
                  </Button>
                </Box>
              </Box>
            ) : (
              this.type === "pre-booked" && (
                //  istanbul ignore next
                <Box style={webStyle.flex1Margin10L}>
                  <Button
                    data-test-id={"btnExample"} //Merge Engine::From BDS
                    color={"primary"}
                    onClick={
                      //  istanbul ignore next
                      () => this.doButtonPressed()
                    }
                  >
                    {configJSON.btnExampleTitle}
                  </Button>
                </Box>
              )
            )}
          </Box>
          <Modal
            data-test-id={"Modal"}
            open={this.state.isEditModalVisible}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box sx={webStyle.modalStyle}>
              <Typography
                variant="h6"
                align="center"
                style={{ marginBottom: "24px", fontWeight: 600 }}
              >
                Edit Reservation{" "}
              </Typography>
              <Typography
                align="center"
                variant="body1"
                style={{ marginTop: 1 }}
              >
                Please Select new slot for Reservation{" "}
              </Typography>
              <FlatList
                testID="productListTestID"
                data={this.state.itemsList}
                renderItem={({ item }) => {
                  return (
                    <Box
                      data-test-id="TouchableTestID"
                      my={2}
                      mb={3}
                      onClick={() => {
                        this.handleSelectNewItem(item);
                      }}
                      style={
                        this.state.selectedItem &&
                        this.state.selectedItem.id &&
                        item.id === this.state.selectedItem.id
                          ? { backgroundColor: "green" }
                          : { backgroundColor: "#ffffff" }
                      }
                    >
                      <Typography variant="body1">
                        {"Place: "} {item.attributes.city}{" "}
                      </Typography>
                      <Typography style={{ marginTop: 2 }} variant="body1">
                        {"Date: "}
                        {moment(item.attributes.reservation_date).format(
                          "DD-MMM-YYYY"
                        )}
                        {" Slot: "}
                        {item.attributes.slot_start_time}
                      </Typography>
                    </Box>
                  );
                }}
                keyExtractor={(item) => item.id}
                extraData={this.state}
              />

              <Divider style={{ marginBottom: "16px" }} />
              <ButtonGroup
                variant="text"
                aria-label="text button group"
                fullWidth
                style={{ borderWidth: 4 }}
              >
                <Button
                  data-test-id="editCancelBtn"
                  onClick={this.hideEditModal}
                >
                  Cancel
                </Button>
                <Button
                  data-test-id="editUpdateBtn"
                  onClick={this.updateReservation}
                >
                  Update
                </Button>
              </ButtonGroup>
            </Box>
          </Modal>

          <Modal
            data-test-id="CancelModal"
            open={this.state.isCancelModalVisible}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box sx={webStyle.modalStyle}>
              <Typography
                variant="h6"
                align="center"
                style={{ marginBottom: "24px", fontWeight: 600 }}
              >
                Cancel Reservation{" "}
              </Typography>
              <Typography
                style={{ marginBottom: "32px" }}
                align="center"
                variant="body1"
              >
                Are you sure you want to cancel <br />
                Reservation?{" "}
              </Typography>

              <Divider style={{ marginBottom: "16px" }} />
              <ButtonGroup
                variant="text"
                aria-label="text button group"
                fullWidth
                style={{ borderWidth: 4 }}
              >
                <Button
                  data-test-id="CancelModalNoBtn"
                  onClick={this.hideCancelModalButtonPress}
                >
                  Cancel
                </Button>
                <Button
                  data-test-id="cancelModalOkBtn"
                  onClick={this.cancelOrderDataRequest}
                >
                  Yes
                </Button>
              </ButtonGroup>
            </Box>
          </Modal>
        </Container>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  productImageWrapper: {
    width: "100px",
    height: "100%",
  },
  productWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "100px",
    marginBottom: "10px",
    alignItems: "center",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: 8,
    transform: "translate(-50%, -50%)",
    // width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  productInfoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "20px",
    alignItems: "center",
  },
  flexRow: { flexDirection: "row", marginTop: 10, display: "flex" },
  radiobuttonOuter: {
    height: 15,
    width: 15,
    borderRadius: 15,
    borderWidth: 1,
    marginRight: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  radiobuttonInner: {
    height: 10,
    width: 10,
    borderRadius: 10,
    backgroundColor: "#2196F3",
  },
  filterButton: {
    flexDirection: "row",
    marginRight: 15,
    alignItems: "center",
    display: "flex",
  },
  margin10V: { marginVertical: 10 },
  width150: { width: 150 },
  rowButtons: { flexDirection: "row", marginTop: 25, display: "flex" },
  flex1Margin10L: { flex: 1, marginLeft: 10, backgroundColor: "#2196F3" },
};
// Customizable Area End
