// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import "react-datepicker/dist/react-datepicker.css";

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";


import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import LoyaltySystem2 from "../../blocks/LoyaltySystem2/src/LoyaltySystem2";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import BlogPostsManagement from "../../blocks/BlogPostsManagement/src/BlogPostsManagement";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import InstantGiftCards from "../../blocks/InstantGiftCards/src/InstantGiftCards";
import Maps from "../../blocks/maps/src/Maps";
import Testimonials from "../../blocks/Testimonials/src/Testimonials";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Videos from "../../blocks/videos/src/Videos";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import CfCountdownTimer2 from "../../blocks/CfCountdownTimer2/src/CfCountdownTimer2";
import PromoteContent from "../../blocks/PromoteContent/src/PromoteContent";
import Customform from "../../blocks/customform/src/Customform";
import Gallery from "../../blocks/Gallery/src/Gallery";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import InventoryManagementIntegration2 from "../../blocks/InventoryManagementIntegration2/src/InventoryManagementIntegration2";
import Analytics from "../../blocks/analytics/src/Analytics";
import CfRazorpay16 from "../../blocks/CfRazorpay16/src/CfRazorpay16";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import ItemAvailability from "../../blocks/ItemAvailability/src/ItemAvailability";
import Trending from "../../blocks/Trending/src/Trending";
import Referrals from "../../blocks/Referrals/src/Referrals";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import CustomAdvertisements from "../../blocks/CustomAdvertisements/src/CustomAdvertisements";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import CfAskingForacceptingTickets from "../../blocks/CfAskingForacceptingTickets/src/CfAskingForacceptingTickets";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CfLinkStreamWithExpirationTime from "../../blocks/CfLinkStreamWithExpirationTime/src/CfLinkStreamWithExpirationTime";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Share from "../../blocks/share/src/Share";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Reservations from "../../blocks/Reservations/src/Reservations";
import ReservationHistory from "../../blocks/Reservations/src/ReservationHistory";
import ReservationsItem from "../../blocks/Reservations/src/ReservationsItem";
import Location from "../../blocks/location/src/Location";
import CfWallet19 from "../../blocks/CfWallet19/src/CfWallet19";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import ProductRecommendationEngine from "../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine";
import CfWhatsappIntegration64 from "../../blocks/CfWhatsappIntegration64/src/CfWhatsappIntegration64";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Hashtags from "../../blocks/Hashtags/src/Hashtags";
import AdManager from "../../blocks/AdManager/src/AdManager";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";




const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
LoyaltySystem2:{
 component:LoyaltySystem2,
path:"/LoyaltySystem2"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SplitPayments:{
 component:SplitPayments,
path:"/SplitPayments"},
BlogPostsManagement:{
 component:BlogPostsManagement,
path:"/BlogPostsManagement"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
InstantGiftCards:{
 component:InstantGiftCards,
path:"/InstantGiftCards"},
Maps:{
 component:Maps,
path:"/Maps"},
Testimonials:{
 component:Testimonials,
path:"/Testimonials"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Videos:{
 component:Videos,
path:"/Videos"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
CfCountdownTimer2:{
 component:CfCountdownTimer2,
path:"/CfCountdownTimer2"},
PromoteContent:{
 component:PromoteContent,
path:"/PromoteContent"},
Customform:{
 component:Customform,
path:"/Customform"},
Gallery:{
 component:Gallery,
path:"/Gallery"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
InventoryManagementIntegration2:{
 component:InventoryManagementIntegration2,
path:"/InventoryManagementIntegration2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
CfRazorpay16:{
 component:CfRazorpay16,
path:"/CfRazorpay16"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
ItemAvailability:{
 component:ItemAvailability,
path:"/ItemAvailability"},
Trending:{
 component:Trending,
path:"/Trending"},
Referrals:{
 component:Referrals,
path:"/Referrals"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
CustomAdvertisements:{
 component:CustomAdvertisements,
path:"/CustomAdvertisements"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
CfAskingForacceptingTickets:{
 component:CfAskingForacceptingTickets,
path:"/CfAskingForacceptingTickets"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
CfLinkStreamWithExpirationTime:{
 component:CfLinkStreamWithExpirationTime,
path:"/CfLinkStreamWithExpirationTime"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Share:{
 component:Share,
path:"/Share"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
ReservationsItem:{
  component:ReservationsItem,
 path:"/ReservationsItem"},
ReservationHistory:{
  component:ReservationHistory,
 path:"/ReservationHistory"},
Location:{
 component:Location,
path:"/Location"},
CfWallet19:{
 component:CfWallet19,
path:"/CfWallet19"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
ProductRecommendationEngine:{
 component:ProductRecommendationEngine,
path:"/ProductRecommendationEngine"},
CfWhatsappIntegration64:{
 component:CfWhatsappIntegration64,
path:"/CfWhatsappIntegration64"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
AdManager:{
 component:AdManager,
path:"/AdManager"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
component:OTPInputAuth,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
