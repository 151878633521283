import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

export interface Item {
  id: string;
  type: string;
  attributes: {
    id: string;
    order_status: string;
    total_price: number;
    placed_date_time: string;
    created_at: string;
    updated_at: string;
    reservation_service: {
      id: string;
      price: number;
      created_at: string;
      updated_at: string;
      city: string;
      full_address: string;
      reservation_date: string;
      state: string;
      zip_code: number;
      service_name: string;
      booking_status: string;
      slot_start_time: string;
      slot_end_time: string;
    };
    reservation_service_image: string;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface SProps {
  // Customizable Area Start
  itemsList: Item[];
  sortType: string;
  isSortLoaderVisible: boolean;
  // Customizable Area End
}

interface SSProps {
  // Customizable Area Start
  ssId: string;
  // Customizable Area End
}

export default class ReservationHistoryController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  // Customizable Area Start
  uniqueSessionRequesterId: string = "";
  authToken: string = "";
  getReservationHistoryApiCallId: string = "";
  sortTypes: string[] = ["Ascending Order", "Descending Order"];
  unsubscribefocuse?: () => void;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      itemsList: [],
      sortType: "Descending Order",
      isSortLoaderVisible: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;
        this.fetchReservationHistory();
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getReservationHistoryApiCallId) {
        this.setState({ itemsList: responseJson.data }, () => {
          this.sortById();
        });
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  requestSessionData() {
    const sessionMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = sessionMsg.messageId;
    this.send(sessionMsg);
    return true;
  }

  async componentDidMount() {
    this.requestSessionData();
    this.unsubscribefocuse = this.props.navigation.addListener("focus", () => {
      this.requestSessionData();
    });
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    this.unsubscribefocuse && this.unsubscribefocuse();
  }

  fetchReservationHistory = () => {
    const headers = {
      token: this.authToken,
    };

    const getReservationHistoryListMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReservationHistoryApiCallId =
      getReservationHistoryListMsg.messageId;

    getReservationHistoryListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetReservationHistory
    );

    getReservationHistoryListMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getReservationHistoryListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReservationHistoryApiMethodType
    );

    runEngine.sendMessage(
      getReservationHistoryListMsg.id,
      getReservationHistoryListMsg
    );

    return true;
  };

  doSortingPressed = (item: string) => {
    this.setState({ sortType: item }, () => {
      this.sortById(item);
    });
  };

  sortById = (item?: string) => {
    this.setState({ isSortLoaderVisible: true });
    let arrayData = [...this.state.itemsList];
    arrayData.sort((item1: Item, item2: Item) => {
      if (item === "Descending Order") {
        return Number(item1.id) < Number(item2.id) ? 1 : -1;
      } else {
        return Number(item1.id) < Number(item2.id) ? -1 : 1;
      }
    });
    this.setState({ itemsList: arrayData }, () => {
      this.setState({ isSortLoaderVisible: false });
    });
  };
  handleViewBooking = (item: Item) => {
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ReservationsItem"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseNavigationMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseNavigationMessage.addData(getName(MessageEnum.SessionResponseData), {
      data: item,
      type: "booked",
    });
    navigationMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseNavigationMessage
    );
    this.send(navigationMessage);
  };

  // Customizable Area End
}
