import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start


export interface Item {
  id: string;
  type: string;
  attributes: {
    id: string;
    price: number;
    state: string;
    full_address: string;
    city: string;
    reservation_date: string;
    zip_code: number,
    created_at: string;
    updated_at: string;
    booking_status: string;
    slot_start_time: string;
    slot_end_time: string;
    image: string | null;
    service_name: string;
  };
}
export interface HistoryItem {
  id: string;
  type: string;
  attributes: {
    id: string;
    order_status: string;
    total_price: number;
    placed_date_time: string;
    created_at: string;
    updated_at: string;
    price?: number;
    state: string;
    full_address: string;
    city: string;
    reservation_date: string;
    zip_code: number,
    booking_status?: string;
    slot_start_time: string;
    slot_end_time: string;
    image: string | null;
    service_name: string;
    reservation_service: {
      id: string;
      price: number;
      created_at: string;
      updated_at: string;
      city: string;
      full_address: string;
      reservation_date: string;
      state: string;
      zip_code: number;
      service_name: string;
      booking_status: string;
      slot_start_time: string;
      slot_end_time: string;
    },
    reservation_service_image: string
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface SProps {
  // Customizable Area Start
  itemsList: Item[];
  isEditModalVisible: boolean;
  isCancelModalVisible: boolean;
  selectedItem: Item | null;
  itemData: Item | null;
  bookingStatus: string;
  // Customizable Area End
}

interface SSProps {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ReservationsItemController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  // Customizable Area Start
  uniqueSessionRequesterId: string = "";
  authToken: string = "";
  getProductItemListApiCallId: string = "";
  createReservationApiCallId: string = '';
  deleteReservationAPiCallId: string = "";
  editReservationApiCallId: string = "";
  itemData: HistoryItem = {
    id: "",
    type: "",
    attributes: {
      id: "",
      order_status: "",
      total_price: 0,
      placed_date_time: "",
      created_at: "",
      updated_at: "",
      price: 0,
      state: "",
      full_address: "",
      city: "",
      reservation_date: "",
      zip_code: 0,
      booking_status: "",
      slot_start_time: "",
      slot_end_time: "",
      image: "",
      service_name: "",
      reservation_service: {
        id: "",
        price: 0,
        created_at: "",
        updated_at: "",
        city: "",
        full_address: "",
        reservation_date: "",
        state: "",
        zip_code: 0,
        service_name: "",
        booking_status: "",
        slot_start_time: "",
        slot_end_time: "",
      },
      reservation_service_image: "",    }
  };
  type: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      itemsList: [],
      isEditModalVisible: false,
      isCancelModalVisible: false,
      selectedItem: null,
      itemData: null,
      bookingStatus: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const itemData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      const { data, type } = itemData;
      this.itemData = data;
      this.type = type;
    }
    else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;
        this.fetchItemList();
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.createReservationApiCallId) {
        this.props.navigation.navigate("ReservationHistory");
      } else if (apiRequestCallId === this.deleteReservationAPiCallId
        && responseJson.data
        && responseJson.data.attributes
        && responseJson.data.attributes.order_status
        && responseJson.data.attributes.order_status === "cancelled") {
        this.setState({ isCancelModalVisible: false, selectedItem: null }, () => { this.props.navigation.navigate("ReservationHistory"); })
      } else if (apiRequestCallId === this.getProductItemListApiCallId) {
        this.setState({
          itemsList: responseJson.data?.filter((item: Item) => {
            return item.attributes?.booking_status !== "booked"
          })
        })
      } else if (apiRequestCallId === this.editReservationApiCallId && responseJson.data) {
        this.setState({ isEditModalVisible: false, selectedItem: null }, () => { this.props.navigation.navigate("ReservationHistory"); })
      }
    }

    
    // Customizable Area End
  }

  // Customizable Area Start
  requestSessionData() {
    const sessionMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = sessionMsg.messageId;
    this.send(sessionMsg);
  }

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed = () => {
    const header = {
      "Content-Type": configJSON.itemListApiContentType,
      token: this.authToken
    };

    const body = {
      user_orders: [{
        reservation_service_id: this.itemData.id
      }]
    }

    const secureReservationMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createReservationApiCallId = secureReservationMsg.messageId;

    secureReservationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlSecureReservationList
    );

    secureReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    secureReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    secureReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.secureReservationApiMethodType
    );

    runEngine.sendMessage(secureReservationMsg.id, secureReservationMsg);
  }

  btnEditProps = {
    onPress: () => this.doButtonEditPressed()
  };
  doButtonEditPressed = () => {
    this.setState({ isEditModalVisible: true });
  }
  hideEditModal = () => {
    this.setState({ isEditModalVisible: false });
  };

  btnCancelProps = {
    onPress: () => this.doButtonCancelPressed()
  };
  doButtonCancelPressed = () => {
    this.setState({ isCancelModalVisible: true });
  }
  hideCancelModalButtonPress = () => {
    this.setState({ isCancelModalVisible: false });
  };

  cancelOrderDataRequest = () => {
    const headers = {
      "token": this.authToken
    };

    const cancelReservationMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteReservationAPiCallId = cancelReservationMsg.messageId;

    cancelReservationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlCancelReservation + this.itemData.id
    );

    cancelReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    cancelReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cancelReservationApiMethodType
    );

    runEngine.sendMessage(cancelReservationMsg.id, cancelReservationMsg);
  }
  fetchItemList = () => {
    const headers = {
      "Content-Type": configJSON.itemListApiContentType,
      "token": this.authToken
    };

    const getItemListMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductItemListApiCallId = getItemListMsg.messageId;

    getItemListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetItemList
    );

    getItemListMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getItemListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.itemListApiMethodType
    );

    runEngine.sendMessage(getItemListMsg.id, getItemListMsg);
    return true;
  }

  handleSelectNewItem = (item: Item) => {
    this.setState({ selectedItem: item })
  }

  updateReservation = () => {
    const headers = {
      "Content-Type": configJSON.itemListApiContentType,
      "token": this.authToken
    };

    const body = {
      user_order: {
        "reservation_service_id": this.state.selectedItem?.id
      }
    }

    const updateReservationMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editReservationApiCallId = updateReservationMsg.messageId;

    updateReservationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlEditReservation + this.itemData.id
    );

    updateReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    updateReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editReservationApiMethodType
    );

    runEngine.sendMessage(updateReservationMsg.id, updateReservationMsg);
  }
  // Customizable Area End
}
