Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Create Account";
exports.labelFirstName = "First Name";
exports.lastName = "Last Name";
exports.labelEmail = "Email id";
exports.labelPassword = "Password";
exports.labelRePassword = "Confirm Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.defaultFirstName = "Enter first name";
exports.defaultLastName = "Enter last name";
exports.defaultLabelEmail = "Enter email id";
exports.defaultLabelPassword = "Enter password";

exports.firstNameError = "First name can not be empty.";
exports.firstNameCharError = 'First name can only contain alphabets.';
exports.lastNameError = "Last name can not be empty.";
exports.lastNameCharError = 'Last name can only contain alphabets.';
exports.mailErrorNull = "Email id can not be empty.";
exports.mailErrorFormat = "Email id format is not valid. Ex. abc@abc.abc";
exports.passwordNull = "Password can not be empty.";
exports.passwordFormat =
  "password should contain minimum length 8, 1 digit, 1 upper case, 1 lower case, 1 special character & no space.";
exports.passwordMatch = "Password and confirm Password are not matching.";
exports.termsAndConditionError = "Please accept Terms & Conditions and Privacy Policy.";

exports.login = "Login";
exports.signUp = "Sign Up";
exports.skip = "SKIP";
exports.or = "OR";
exports.haveAnAccount = "Already have an Account?";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms & Conditions";
exports.labelLegalPrivacyPolicy = "Privacy Policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint = "account_block/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.urlGetPrivacyPolicy = "bx_block_privacy_policy/policy";
exports.urlGetTermsAndCondition = "bx_block_termsandconditions/terms_and_conditions";
// Customizable Area End
